import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {pipe} from 'fp-ts/pipeable';
import {
  makeStyles, ThemeProvider
} from '@material-ui/styles';
import { without, append, includes, keys, map, values, reject, assocPath, flatten, split, last } from 'ramda';
import {
  Typography, Button, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, Badge,
  TextField, LinearProgress, useMediaQuery, Paper, Divider, Collapse
} from '@material-ui/core';
import {
  green
} from '@material-ui/core/colors';
import {API, graphqlOperation} from 'aws-amplify';
import ReactPhoneInput from 'react-phone-input-mui';
import {
  ArrowForward, Check, ArrowBack, ArrowForwardIos as ArrowRight, Error
} from '@material-ui/icons';
import {
  update,
  initialState as CareersState,
  submitForm
} from '../../state/careers/ducks';
import theme from '../../themes/lightTheme';

import * as mutations from '../../graphql/mutations';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    maxWidth: '100vw'

  },
  form: {
    maxWidth: 900,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    }
  },
  header: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 22
    }, maxWidth: '100vw'
  },
  subheader: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }, maxWidth: '100vw'
  },
  formBody: {
    maxWidth: 900,
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw'
    }

  },
  progress: {
    padding: theme.spacing()
  },
  paperRoot: {
    border: `2px solid ${theme.palette.text.primary}`,
    background: theme.palette.background.default
  },
  btnGroup: {
    display: 'flex',
    // gap: `${theme.spacing(2)}px`,
    justifyContent: 'center',
    padding: theme.spacing(),
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexGrow: 1
    }
  },
  badgeCheck: {
    fontSize: 10
  },
  badge: {
    margin: theme.spacing()
  },
  unselected: {
    border: `2px solid ${theme.palette.text.disabled}`,
  },
  selected: {
    border: `2px solid ${theme.palette.text.primary}`,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(),
    marginBottom: theme.spacing(),
    color: theme.palette.text.primary,
    flexGrow: 1
  },
  success: {
    backgroundColor: green[500],
    padding: theme.spacing(),
  },
  successContent: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
  },
  phoneInput: {
    width: '100%',
    flexGrow: 1
  },
  questionContainer: {
    width: '100%'
  },
  questionReview: {
    marginBottom: theme.spacing(),
    // minWidth: 500,
    width: '100%',

  },
  submitAnotherFormBtn: {
    textTransform: 'none',
    color: 'white',
    marginTop: theme.spacing(2),
    fontStyle: 'italic'
  },
  collapsed: {
    transition: 'transform 300ms'
  },
  unCollapse: {
    transform: 'rotate(0.25turn)',
    transition: 'transform 300ms'
  },
  missingFieldsTitleContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.text.primary
  },
  arrow: {
    color: theme.palette.text.primary
  },
  missingFieldsTitle: {
    display: 'flex',
    alignItems: 'center',
  }
}));

const Question = ({
  questions,
  currentQuestion,
  review,
  updateButton,
  updateTextField,
  updatePhoneField,
  answer,
  updateFileField
}) => {
  const classes = useStyles();
  const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });
  const getBase64String = value => pipe(value,
    v => split(',', v),
    v => last(v)
  );
  const acceptFile = async (e) => {
    e.persist();
    const files = await Promise.all(values(e.target.files).map(async (f) => ({
      content: await toBase64(f).then(res => getBase64String(res)),
      filename: f.name,
      encoding: 'base64'
    })));
    updateFileField(files, currentQuestion)
    // e.target.files[0] && toBase64(e.target.files[0]).then(res => updateFileField(res, currentQuestion));
  }
  return (
      <div className={classes.form}>
        {questions[currentQuestion]?.multipleSelect && <Typography align='center' variant='caption'>Select all that apply</Typography>}
        <div className={classes.btnGroup}>
          {questions[currentQuestion]?.options?.map(option =>
            <Badge
              className={classes.badge}
              key={option.value}
              color='primary'
              badgeContent={<Check className={classes.badgeCheck} />}
              invisible={!(questions[currentQuestion]?.multipleSelect && includes(option, answer)) && questions[currentQuestion]?.answer?.label !== option.label}
            >
              <Button
                fullWidth
                onClick={(e) => updateButton(option, currentQuestion)}
                className={(questions[currentQuestion]?.answer?.label === option.label || (questions[currentQuestion]?.multipleSelect && includes(option, answer))) ? classes.selected : classes.unselected}
                variant='outlined'>
                {option.label}
              </Button>
            </Badge>)}
            {questions[currentQuestion]?.inputPropType === 'file' && <div>
              <input
                multiple
                onChange={acceptFile}
                id={questions[currentQuestion]?.type}
                name={questions[currentQuestion]?.type}
                type={questions[currentQuestion]?.inputPropType} />
            </div>}
          {!questions[currentQuestion]?.options && currentQuestion !== 'phone' && questions[currentQuestion]?.inputPropType !== 'file' &&
            <TextField
              fullWidth={true}
              required={Boolean(questions[currentQuestion]?.required)}
              multiline={true}//Boolean(questions[currentQuestion]?.multiline)}
              name={questions[currentQuestion]?.inputPropType}
              type={questions[currentQuestion]?.inputPropType}
              placeholder={questions[currentQuestion]?.label}
              label={questions[currentQuestion]?.label}
              InputLabelProps={{
                shrink: questions[currentQuestion]?.inputPropType === 'date' || Boolean(questions[currentQuestion]?.answer?.value) || questions[currentQuestion]?.answer?.value === ''
              }}
              value={questions[currentQuestion]?.answer?.value || ''}
              onChange={e => updateTextField(e, currentQuestion)} />}
          {/*{!questions[currentQuestion]?.options && currentQuestion === 'phone' &&
            <ReactPhoneInput
              defaultCountry='us'
              disableCountryCode
              placeholder={questions[currentQuestion]?.label}
              value={questions[currentQuestion]?.answer?.value || ''}
              onChange={e => updatePhoneField(e, currentQuestion)}
              containerClass={classes.phoneInput}
              inputExtraProps={{
                name: 'phone',
                required: true,
                fullWidth: true,
                label: questions[currentQuestion]?.label
              }}
              component={TextField} />}*/}
            {!questions[currentQuestion]?.options && currentQuestion === 'phone' &&
              <TextField
                fullWidth
                autoFocus={false}
                multiline={Boolean(questions[currentQuestion]?.multiline)}
                name={questions[currentQuestion]?.inputPropType}
                type={questions[currentQuestion]?.inputPropType}
                placeholder={questions[currentQuestion]?.label}
                value={questions[currentQuestion]?.answer?.value || ''}
                onChange={e => updateTextField(e, currentQuestion)} />}
        </div>
        {questions[currentQuestion]?.answer?.label === 'Other' &&
          <TextField
            value={questions[currentQuestion]?.answer?.value || ''}
            fullWidth
            required={questions[currentQuestion]?.required}
            autoFocus
            placeholder='Other...'
            onChange={e => updateTextField(e, currentQuestion)} />}
      </div>

  )
}

const FormComponent = ({
  careers,
  update,
  submitForm
}: {
  careers: typeof CareersState;
  update: any;
}) => {
  const classes = useStyles();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = React.useState({data: null});
  const query = `
    query {
      listProjectForms {
       items {
         email
         firstName
         id
       }
     }
    }
  `;

  const reviewForm = () => {
    update(['form', 'review'], true);
  }
  const {
    form: {
      currentQuestion,
      questions,
      review,
      submitSuccess
    }
  } = careers;
  // if (!currentQuestion) return null;
  const close = () => update(['form', 'currentQuestion'], null);
  const updateTextField = (e, currentQuestion) => {
    update(['form', 'questions', currentQuestion, 'answer', 'value'], e.target.value);
  };
  const updateFileField = (val, currentQuestion) => {
    update(['form', 'questions', currentQuestion, 'answer', 'value'], val);
  };
  const updatePhoneField = (value, currentQuestion) => {
    update(['form', 'questions', currentQuestion, 'answer', 'value'], value);
  };
  const answer = questions[currentQuestion]?.answer;
  const updateButton = (value, currentQuestion) => {
    if (questions[currentQuestion]?.multipleSelect) {
      return update(
        ['form', 'questions', currentQuestion, 'answer'],
        includes(value, answer) ? answer.filter(i => i !== value) : append(value, answer)
      )
    }
    return update(['form', 'questions', currentQuestion, 'answer'], value)
  }
  const curQuestionIdx = Object.values(questions).indexOf(questions[currentQuestion]);
  const nextQuestionId = curQuestionIdx !== undefined && Object.values(questions)?.[curQuestionIdx + 1]?.type;
  const previousQuestionId = curQuestionIdx !== undefined && Object.values(questions)?.[curQuestionIdx - 1]?.type;
  const progress = ((curQuestionIdx) / (Object.keys(questions).length - 1)) * 100;
  const resetForm = () => update(['form', 'submitSuccess'], false);
  const smallerFields = [
    "First Name", "Last Name", "Middle Name",
    "Start Date", "Email", "Phone",
    "City", "State/Province", "Postal / Zip Code",
    "Phone Number (Contact 1)", "Phone Number (Contact 2)", "Phone Number (Contact 3)",
    "Email (Contact 1)", "Email (Contact 2)", "Email (Contact 3)",
  ];
  const hiddenFields = pipe(questions,
    q => values(q),
    q => q.filter(x => x.subFields),
    q => q.map(x => x.subFields),
    q => flatten(q),
    q => reject(x => x.keep, q),
    q => q.map(x => x.key)
  );
  const requiredQuestionsRemaining = pipe(
    questions,
    q => values(q),
    q => q.filter(x => x.required),
    q => q.filter(x => !x.answer || x.answer?.value === ''),
  );
  const fields = pipe(questions,
    q => keys(q),
    ks => reject(k => questions?.[k]?.noInput, ks),
    ks => reject(k => questions?.[k]?.inputPropType === 'file', ks),
    ks => reject(k => questions?.[k]?.answer === null, ks),
    ks => reject(k => includes(k, hiddenFields), ks),
    ks => ks?.reduce((acc, k) => pipe(acc,
      a => assocPath([k], questions?.[k]?.answer?.value, a),
      a => assocPath([k, 'value'], questions?.[k]?.answer?.value, a),
      a => assocPath([k, 'subFields'], questions?.[k]?.subFields, a),
      a => assocPath(
        [k, 'label'],
        questions?.[k]?.label
          || questions?.[k]?.questionText
          || questions?.[k]?.questionTextHeader
          || questions?.[k]?.questionTextSubHeader, a),
    ), {}),

  );
  const generateTableRows = (fields) => {
    const rows = fields.reduce((acc, val, idx) => {
      const headRow = ((idx % 2) === 0) ? `<tr style="background:#eee;">` : "<tr>";
      const value = val.subFields ? val.subFields?.reduce((acc, v) => {
        if (!questions[v.key]?.answer?.value) return acc;
        return acc.concat(`<p>${v.label || questions?.[v.key]?.label}: ${questions[v.key]?.answer?.value}</p>`);
      }, '') : `${val.value}`;
      return acc.concat(
        headRow
          + `<th style="max-width: 300px;text-align:left;">`
            + `${val.label}`
          + "</th>"
          + "<td>"
            + value
          + "</td>"
        + "</tr>"
      );
    }, '');
    const html =
    `<h1 style="font-family:Arial;">C&R Asphalt Employment Form</h1>`
    + `<table style="font-family:Arial;border:2px solid rgb(237, 28, 36);">`
    + rows
    + `</table>`
    return html;
  }
  const emailPreview = generateTableRows(values(fields));
  const [collapseState, setCollapseState] = React.useState({collapsed: false});
  const toggleCollapse = () => {
    if (collapseState.collapsed) {
      setCollapseState({collapsed: false});
      return;
    }
    setCollapseState({collapsed: true});
    return;
  }
  React.useEffect(() => {
    update(['form', 'report'], emailPreview);
    update(['form', 'reportFiles'], questions['resumeFile']?.answer?.value);
  }, [questions]);
  return(
  <ThemeProvider theme={theme}>
    {/*<div style={{maxWidth: 600, margin: 'auto'}}>
      <div dangerouslySetInnerHTML={{__html: emailPreview}} />
    </div>*/}

    <div className={classes.root}>
      {submitSuccess && <div>
          <Paper className={classes.success}>
            <div className={classes.successContent}>
            <Check fontSize='large' />
            <Typography>
              Success! Thank you for applying with us.
            </Typography>
            </div>
            <Button onClick={resetForm} fullWidth className={classes.submitAnotherFormBtn}>Click sere to submit another form.</Button>
          </Paper>
      </div>}
      {!submitSuccess && <div className={classes.formBody}>
        <Typography className={classes.header} variant='h4'>Application For Employment</Typography>
            <div className={classes.formBody}>
              {keys(questions).map((q, i) => <div key={i} style={!includes(questions[q]?.label, smallerFields) ? {width: '100%'} : {}}>
                <div className={classes.questionContainer}>
                  {Boolean(questions[q]?.questionText || questions[q]?.questionTextHeader || questions[q]?.questionTextTitle) && <Divider style={{marginTop: theme.spacing(2)}} />}
                  <Typography className={classes.subheader} variant='h5'>{questions[q]?.questionTextTitle}</Typography>
                  <ul style={{maxWidth: '100vw'}}>{questions[q]?.questionTextBullets?.map(b => <li><Typography style={{fontWeight: 'bold'}} variant='caption'>{b}</Typography></li>)}</ul>
                  <Typography style={{fontWeight: 'bold', maxWidth: '100vw'}} variant='body1'>{questions[q]?.questionTextHeader}</Typography>
                  <Typography style={{maxWidth: '100vw'}} variant='body2'>{questions[q]?.questionTextSubHeader}</Typography>
                  <Typography style={{maxWidth: '100vw'}} variant='overline'>{questions[q]?.questionText}</Typography>
                  {!questions[q]?.noInput && <div style={(includes(questions[q]?.label, smallerFields) && !screenIsSmall) ? {minWidth: '300px'} : {}} className={classes.questionReview}>
                    <Question
                      review={review}
                      currentQuestion={q}
                      questions={questions}
                      updateTextField={updateTextField}
                      updateButton={updateButton}
                      updateFileField={updateFileField}
                      updatePhoneField={updatePhoneField}
                      answer={questions[q]?.answer} />
                    </div>}
                    </div>
              </div>)}
          </div>
          <div>
            {careers.form.submitFormError?.msg && <Paper className={classes.error}><Typography>{careers.form.submitFormError?.msg}</Typography></Paper>}
            {requiredQuestionsRemaining.length > 1 &&
              <Paper className={classes.error}>
                <div className={classes.missingFieldsTitleContainer}>
                  <div className={classes.missingFieldsTitle}>
                    <Error />
                    <Typography style={{fontWeight: 'bold', marginLeft: theme.spacing()}}>
                      Missing {requiredQuestionsRemaining.length} required fields
                    </Typography>
                  </div>
                  <IconButton onClick={toggleCollapse} className={collapseState.collapsed ? classes.collapsed : classes.unCollapse}><ArrowRight className={classes.arrow} /></IconButton>
                </div>
                <Collapse in={!collapseState.collapsed}>
                  <div>
                    {requiredQuestionsRemaining.map((q, idx) =>
                      <Typography style={{fontSize: 14}} key={idx}>
                        {`${idx + 1}. `}
                        {q.label || q.questionText || q.questionTextHeader || q.questionTextSubHeader || q.questionTextTitle}
                      </Typography>)
                    }
                  </div>
                </Collapse>
              </Paper>}
            <Button
                onClick={submitForm}
                disabled={careers.form.submittingForm || requiredQuestionsRemaining.length > 1}
                variant='contained'
                color='primary'>
                {careers.form.submittingForm ? 'Submitting Form...' : `Submit`}
          </Button>
          </div>
        </div>}
      </div>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: {
  careers: typeof CareersState
}) => {
  const { careers } = state;
  return {
    careers
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({
      update,
      submitForm
    }, dispatch)
  };
};

const Form = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);

export default Form;
