import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Img from "gatsby-image";
import {
  makeStyles
} from '@material-ui/styles';
// import {
//   Typography, Button, useMediaQuery
// } from '@material-ui/core';
import { Link } from 'gatsby';
import {
  ArrowForward as ArrowRight
} from '@material-ui/icons';
import {
  PlayArrowRounded as Play
} from '@material-ui/icons';


import PortableText from '../content/portableText';
import {
  update,
} from '../../state/project/ducks';
import theme from '../../themes/baseTheme';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
  },
  content: {
    // width: 1100,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      // paddingLeft: theme.spacing(),
      // paddingRight: theme.spacing(),
    }
  }

}))
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const HeroComponent = ({
  update,
  content
}: {
  update: (keypath: string[], value: any) => void;
}) => {
  const classes = useStyles();
  return <main className={classes.root}>
    <article className={classes.content}>
      <PortableText blocks={content} />
    </article>
  </main>
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({
            update
        }, dispatch)
    };
};

const Hero = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeroComponent);

export default Hero;
